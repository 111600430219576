import React, { useState } from "react";

const CoworkingForm = () => {
  const [basicinfo, setBasicInfo] = useState({
    coworkingName: "",
    ownerName: "",
    contactNumber: "",
    emailID: "",
  });

  const [coworking, setCoworking] = useState([
    {
      locations: {
        city: "",
        managerName: "",
        managerContact: "",
        managerEmail: "",
        images: [],
      },
      plans: {
        mailHandling: "",
        gstRegistration: "",
        businessRegistration: "",
        gstWithSeat: "",
        businessWithSeat: "",
        hotDesk: "",
        dedicatedDesk: "",
        cabinPrices: "",
        customPlan: "",
      },
      facilities: {
        essential: {
          mailHandling: false,
          gstRegistration: false,
          businessRegistration: false,
          gstWithSeat: false,
          businessWithSeat: false,
        },
        coworkingdetail: {
          meetingRoom: false,
          conferenceRoom: false,
          internetLAN: false,
          internetWifi: false,
          printing: false,
          phoneBooth: false,
          lockerStorage: false,
          pantry: false,
          wetPantry: false,
          roundClockAccess: false,
          securityCamera: false,
          securityPerson: false,
          projector: false,
          parking: false,
          petFriendly: false,
          adminSupport: false,
        },
        additional: {
          gameZone: false,
          relaxationZone: false,
          gymArea: false,
          yogaArea: false,
          napRoom: false,
          outdoorSpace: false,
          eventSpace: false,
          itSupport: false,
          personalServer: false,
          networkingEvent: false,
          workshops: false,
          lounge: false,
          professionalServices: false,
        },
      },
      remarks: "",
    },
  ]);

  const [loading, setLoading] = useState(false);

  // Add a new coworking form
  const addCoworking = () => {
    const newCoworking = {
      locations: {
        city: "",
        managerName: "",
        managerContact: "",
        managerEmail: "",
        images: [],
      },
      plans: {
        mailHandling: "",
        gstRegistration: "",
        businessRegistration: "",
        gstWithSeat: "",
        businessWithSeat: "",
        hotDesk: "",
        dedicatedDesk: "",
        cabinPrices: "",
        customPlan: "",
      },
      facilities: {
        essential: {
          mailHandling: false,
          gstRegistration: false,
          businessRegistration: false,
          gstWithSeat: false,
          businessWithSeat: false,
        },
        coworkingdetail: {
          meetingRoom: false,
          conferenceRoom: false,
          internetLAN: false,
          internetWifi: false,
          printing: false,
          phoneBooth: false,
          lockerStorage: false,
          pantry: false,
          wetPantry: false,
          roundClockAccess: false,
          securityCamera: false,
          securityPerson: false,
          projector: false,
          parking: false,
          petFriendly: false,
          adminSupport: false,
        },
        additional: {
          gameZone: false,
          relaxationZone: false,
          gymArea: false,
          yogaArea: false,
          napRoom: false,
          outdoorSpace: false,
          eventSpace: false,
          itSupport: false,
          personalServer: false,
          networkingEvent: false,
          workshops: false,
          lounge: false,
          professionalServices: false,
        },
      },
      remarks: "",
    };

    setCoworking((prevCoworking) => [...prevCoworking, newCoworking]);
  };

  //remove coworking form
  const removeCoworking = (index) => {
    const updatedCoworking = coworking.filter((_, i) => i !== index);
    setCoworking(updatedCoworking);
  };

  // Handle changes in the location section of the form
  const handleLocationChange = (index, e) => {
    const { name, value } = e.target;
    const updatedCoworking = [...coworking];
    updatedCoworking[index].locations[name] = value;
    setCoworking(updatedCoworking);
  };

  // Handle image uploads for locations
  const handleImageUpload = (index, e) => {
    const files = Array.from(e.target.files);

    // Check if all files are images
    const isValidImage = files.every((file) => file.type.startsWith("image/"));
    if (!isValidImage) {
      alert("Only image files are allowed!");
      e.target.value = ""; // Clear the input if invalid
      return;
    }

    // Ensure no file exceeds the 2MB size limit
    const tooLargeFile = files.find((file) => file.size > 2097152);
    if (tooLargeFile) {
      alert("File size should be below 2MB");
      e.target.value = ""; // Clear the input if file size exceeds limit
      return;
    }

    // Ensure that the index is valid for the coworking array
    if (index < 0 || index >= coworking.length) {
      alert("Invalid index");
      e.target.value = "";
      return;
    }

    // Check if the image limit (4 images) is exceeded
    const currentImages = coworking[index].locations.images || [];
    if (currentImages.length + files.length > 4) {
      alert("You can only upload up to 4 images.");
      e.target.value = ""; // Clear the input if it exceeds the limit
      return;
    }

    // Update the coworking object with the new images
    const updatedCoworking = [...coworking];
    updatedCoworking[index].locations.images = [...currentImages, ...files];

    setCoworking(updatedCoworking);
  };

  // Handle removing images from locations
  const handleImageRemove = (index, imageIndex) => {
    const updatedCoworking = [...coworking];
    updatedCoworking[index].locations.images = updatedCoworking[
      index
    ].locations.images.filter((_, idx) => idx !== imageIndex);
    setCoworking(updatedCoworking);
  };

  // Handle changes in the pricing plans
  const handlePlanChange = (e, index) => {
    const { name, value } = e.target;
    const updatedCoworking = [...coworking];
    updatedCoworking[index].plans[name] = value;
    setCoworking(updatedCoworking);
  };

  // Handle checkbox changes in the facilities section
  const handleFacilityChange = (category, facility, index) => {
    const updatedCoworking = [...coworking];
    updatedCoworking[index].facilities[category][facility] =
      !updatedCoworking[index].facilities[category][facility];
    setCoworking(updatedCoworking);
  };

  // Handle changes in the remarks section
  const handleRemarksChange = (index, e) => {
    const updatedCoworking = [...coworking];
    updatedCoworking[index].remarks = e.target.value;
    setCoworking(updatedCoworking);
  };

  const [coworkingErrors, setCoworkingErrors] = useState([]);

  const [basicInfoErrors, setBasicInfoErrors] = useState({});
  // Validation for basic info
  const validateBasicInfo = () => {
    const errors = {};

    if (!basicinfo.coworkingName.trim()) {
      errors.coworkingName = "Coworking name is required.";
    }
    if (!basicinfo.ownerName.trim()) {
      errors.ownerName = "Owner name is required.";
    }
    if (!/^[0-9]{10}$/.test(basicinfo.contactNumber)) {
      errors.contactNumber = "Contact number must be a valid 10-digit number.";
    }
    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(basicinfo.emailID)) {
      errors.emailID = "Email ID must be a valid email address.";
    }

    setBasicInfoErrors(errors);
    return Object.keys(errors).length === 0;
  };

  // Validation for coworking locations
  const validateCoworking = () => {
    const errors = coworking.map((cowork) => {
      const locationErrors = {};
      const plansErrors = {};

      // Validate locations
      if (!cowork.locations.city.trim()) {
        locationErrors.city = "City is required.";
      }
      if (!cowork.locations.managerName.trim()) {
        locationErrors.managerName = "Manager name is required.";
      }
      if (!/^[0-9]{10}$/.test(cowork.locations.managerContact)) {
        locationErrors.managerContact =
          "Manager contact must be a valid 10-digit number.";
      }
      if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(cowork.locations.managerEmail)) {
        locationErrors.managerEmail =
          "Manager email must be a valid email address.";
      }

      // Validate plans
      Object.keys(cowork.plans).forEach((plan) => {
        if (!cowork.plans[plan].trim()) {
          plansErrors[plan] = `${plan.replace(/([A-Z])/g, " $1")} is required.`;
        } else if (
          isNaN(cowork.plans[plan]) ||
          parseFloat(cowork.plans[plan]) < 0
        ) {
          plansErrors[plan] = `${plan.replace(
            /([A-Z])/g,
            " $1"
          )} must be a valid price (non-negative number).`;
        }
      });

      return { locations: locationErrors, plans: plansErrors };
    });

    setCoworkingErrors(errors);
    return errors.every(
      (error) =>
        Object.keys(error.locations).length === 0 &&
        Object.keys(error.plans).length === 0
    );
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();

    const isBasicInfoValid = validateBasicInfo();
    const isCoworkingValid = validateCoworking();

    if (isBasicInfoValid && isCoworkingValid) {
      setLoading(true);
      // Submit logic goes here...
      console.log(basicinfo, coworking);

      setLoading(false);
    } else {
    }
  };

  return (
    <div className='container mt-4'>
      <h2 className='mb-4 text-center'>Coworking Space Details</h2>
      <form onSubmit={(e) => handleFormSubmit(e)}>
        {/* Basic Information */}
        {/* <div className='row'>
          <div className='col-md-6 col-sm-12'>
            <label className='form-label'>Coworking Space Name</label>
            <input
              type='text'
              className='form-control'
              name='coworkingName'
              value={basicinfo.coworkingName}
              onChange={(e) =>
                setBasicInfo({ ...basicinfo, coworkingName: e.target.value })
              }
            />
          </div>
          <div className='col-md-6 col-sm-12'>
            <label className='form-label'>Owner Name</label>
            <input
              type='text'
              className='form-control'
              name='ownerName'
              value={basicinfo.ownerName}
              onChange={(e) =>
                setBasicInfo({ ...basicinfo, ownerName: e.target.value })
              }
            />
          </div>
          <div className='col-md-6 col-sm-12'>
            <label className='form-label'>Contact Number</label>
            <input
              type='text'
              className='form-control'
              name='contactNumber'
              value={basicinfo.contactNumber}
              onChange={(e) =>
                setBasicInfo({ ...basicinfo, contactNumber: e.target.value })
              }
            />
          </div>
          <div className='col-md-6 col-sm-12'>
            <label className='form-label'>Email ID</label>
            <input
              type='email'
              className='form-control'
              name='emailID'
              value={basicinfo.emailID}
              onChange={(e) =>
                setBasicInfo({ ...basicinfo, emailID: e.target.value })
              }
            />
          </div>
        </div> */}
        <div className='row'>
          <div className='col-md-6 col-sm-12'>
            <label className='form-label'>Coworking Space Name</label>
            <input
              type='text'
              className={`form-control ${
                basicInfoErrors.coworkingName ? "is-invalid" : ""
              }`}
              name='coworkingName'
              value={basicinfo.coworkingName}
              onChange={(e) =>
                setBasicInfo({ ...basicinfo, coworkingName: e.target.value })
              }
            />
            <div className='invalid-feedback'>
              {basicInfoErrors.coworkingName}
            </div>
          </div>
          <div className='col-md-6 col-sm-12'>
            <label className='form-label'>Owner Name</label>
            <input
              type='text'
              className={`form-control ${
                basicInfoErrors.ownerName ? "is-invalid" : ""
              }`}
              name='ownerName'
              value={basicinfo.ownerName}
              onChange={(e) =>
                setBasicInfo({ ...basicinfo, ownerName: e.target.value })
              }
            />
            <div className='invalid-feedback'>{basicInfoErrors.ownerName}</div>
          </div>

          <div className='col-md-6 col-sm-12'>
            <label className='form-label'>Contact Number</label>
            <input
              type='text'
              className={`form-control ${
                basicInfoErrors.contactNumber ? "is-invalid" : ""
              }`}
              name='contactNumber'
              value={basicinfo.contactNumber}
              onChange={(e) =>
                setBasicInfo({ ...basicinfo, contactNumber: e.target.value })
              }
            />
            <div className='invalid-feedback'>
              {basicInfoErrors.contactNumber}
            </div>
          </div>
          <div className='col-md-6 col-sm-12'>
            <label className='form-label'>Email ID</label>
            <input
              type='email'
              className={`form-control ${
                basicInfoErrors.emailID ? "is-invalid" : ""
              }`}
              name='emailID'
              value={basicinfo.emailID}
              onChange={(e) =>
                setBasicInfo({ ...basicinfo, emailID: e.target.value })
              }
            />
            <div className='invalid-feedback'>{basicInfoErrors.emailID}</div>
          </div>
        </div>

        <br className='my-4' />
        <hr />

        {coworking.map((cowork, index) => (
          <div key={index}>
            {/* Location Section */}
            <h4>Coworking Location </h4>
            {/* <div className='row mb-3'>
              <div className='col-md-3'>
                <input
                  type='text'
                  className='form-control'
                  name='city'
                  value={cowork.locations.city}
                  onChange={(e) => handleLocationChange(index, e)}
                  placeholder='City'
                />
              </div>
              <div className='col-md-3'>
                <input
                  type='text'
                  className='form-control'
                  name='managerName'
                  value={cowork.locations.managerName}
                  onChange={(e) => handleLocationChange(index, e)}
                  placeholder='Manager Name'
                />
              </div>
              <div className='col-md-3'>
                <input
                  type='text'
                  className='form-control'
                  name='managerContact'
                  value={cowork.locations.managerContact}
                  onChange={(e) => handleLocationChange(index, e)}
                  placeholder='Manager Contact'
                />
              </div>
              <div className='col-md-3'>
                <input
                  type='email'
                  className='form-control'
                  name='managerEmail'
                  value={cowork.locations.managerEmail}
                  onChange={(e) => handleLocationChange(index, e)}
                  placeholder='Manager Email'
                />
              </div>
            </div> */}
            <div className='row mb-3'>
              <div className='col-md-3'>
                <input
                  type='text'
                  className={`form-control ${
                    coworkingErrors[index]?.locations.city ? "is-invalid" : ""
                  }`}
                  name='city'
                  value={cowork.locations.city}
                  onChange={(e) => handleLocationChange(index, e)}
                  placeholder='City'
                />
                <div className='invalid-feedback'>
                  {coworkingErrors[index]?.locations.city}
                </div>
              </div>
              <div className='col-md-3'>
                <input
                  type='text'
                  className={`form-control ${
                    coworkingErrors[index]?.locations.managerName
                      ? "is-invalid"
                      : ""
                  }`}
                  name='managerName'
                  value={cowork.locations.managerName}
                  onChange={(e) => handleLocationChange(index, e)}
                  placeholder='Manager Name'
                />
                <div className='invalid-feedback'>
                  {coworkingErrors[index]?.locations.managerName}
                </div>
              </div>
              <div className='col-md-3'>
                <input
                  type='text'
                  className={`form-control ${
                    coworkingErrors[index]?.locations.managerContact
                      ? "is-invalid"
                      : ""
                  }`}
                  name='managerContact'
                  value={cowork.locations.managerContact}
                  onChange={(e) => handleLocationChange(index, e)}
                  placeholder='Manager Contact'
                />
                <div className='invalid-feedback'>
                  {coworkingErrors[index]?.locations.managerContact}
                </div>
              </div>
              <div className='col-md-3'>
                <input
                  type='email'
                  className={`form-control ${
                    coworkingErrors[index]?.locations.managerEmail
                      ? "is-invalid"
                      : ""
                  }`}
                  name='managerEmail'
                  value={cowork.locations.managerEmail}
                  onChange={(e) => handleLocationChange(index, e)}
                  placeholder='Manager Email'
                />
                <div className='invalid-feedback'>
                  {coworkingErrors[index]?.locations.managerEmail}
                </div>
              </div>
            </div>
            {/* Image Upload */}
            <div className='row'>
              <label className='form-label'>
                Upload Photos (Max 4 per location, up to 2 MB)
              </label>
              <input
                type='file'
                className='form-control'
                accept='image/*'
                id='imginput'
                onChange={(e) => handleImageUpload(index, e)}
              />
              <small className='form-text text-muted'>
                Max 4 photos per location (each up to 2 MB)
              </small>
            </div>
            <div className='row'>
              {cowork.locations.images.map((image, imageIndex) => (
                <div key={imageIndex} className='col-md-3 col-sm-12 my-1'>
                  <img
                    src={URL.createObjectURL(image)}
                    alt='location'
                    style={{ width: "100%" }}
                  />
                  <button
                    type='button'
                    className='btn btn-danger btn-sm mt-2'
                    onClick={() => handleImageRemove(index, imageIndex)}
                  >
                    Remove
                  </button>
                </div>
              ))}
            </div>

            {/* Pricing Plans */}
            <br className='my-4' />
            <hr />
            <h5>Pricing Plans</h5>
            <div className='row'>
              {/* {Object.keys(cowork.plans).map((plan) => (
                <div key={plan} className='col-md-6 col-sm-12 text-capitalize'>
                  <label className='form-label'>
                    {plan.replace(/([A-Z])/g, " $1")}
                  </label>
                  <input
                    type='text'
                    className='form-control'
                    name={plan}
                    value={cowork.plans[plan]}
                    onChange={(e) => handlePlanChange(e, index)}
                    placeholder='Price'
                  />
                  <div className='invalid-feedback'>
                    {coworkingErrors[index]?.plans?.[plan]}
                  </div>
                </div>
              ))} */}
              {Object.keys(cowork.plans).map((plan) => (
                <div key={plan} className='col-md-6 col-sm-12 text-capitalize'>
                  <label className='form-label'>
                    {plan.replace(/([A-Z])/g, " $1")}
                  </label>
                  <input
                    type='number'
                    className={`form-control ${
                      coworkingErrors[index]?.plans?.[plan] ? "is-invalid" : ""
                    }`}
                    name={plan}
                    value={cowork.plans[plan]}
                    onChange={(e) => handlePlanChange(e, index)}
                    placeholder='Price'
                  />
                  <div className='invalid-feedback'>
                    {coworkingErrors[index]?.plans?.[plan]}
                  </div>
                </div>
              ))}
            </div>
            <div className='row'></div>

            {/* Facilities */}
            <br className='my-4' />
            <hr />
            <h5>Facilities</h5>
            {["essential", "coworkingdetail", "additional"].map((category) => (
              <div key={category} className='row'>
                <h6>{category.replace(/([A-Z])/g, " $1")}</h6>
                {Object.keys(cowork.facilities[category]).map((facility) => (
                  <div
                    key={facility}
                    className='col-md-3 col-sm-10 d-flex flex-wrap'
                  >
                    <div className='form-check m-2'>
                      <input
                        type='checkbox'
                        className='form-check-input'
                        checked={cowork.facilities[category][facility]}
                        onChange={() =>
                          handleFacilityChange(category, facility, index)
                        }
                      />
                      <label className='form-check-label'>
                        {facility.replace(/([A-Z])/g, " $1")}
                      </label>
                    </div>
                  </div>
                ))}
              </div>
            ))}

            {/* Remarks */}
            <br className='my-4' />
            <hr />
            <h5>Remarks</h5>
            <div className='row'>
              <div className='col-12'>
                <textarea
                  className='form-control my-3'
                  value={cowork.remarks}
                  onChange={(e) => handleRemarksChange(index, e)}
                  placeholder='Any additional remarks'
                />
              </div>
            </div>
            {coworking.length > 1 ? (
              <div className='row text-center my-3'>
                <div className='col'>
                  <button
                    type='button'
                    className='btn btn-danger '
                    onClick={() => removeCoworking(index)}
                  >
                    remove co-working place
                  </button>
                </div>
              </div>
            ) : null}
          </div>
        ))}

        {/* Add New Coworking */}
        <div className='row text-center'>
          <div className='col my-3 '>
            <button
              type='button'
              className='btn btn-primary '
              onClick={addCoworking}
            >
              Add co-working place
            </button>
          </div>
        </div>

        {/* Submit Button */}
        <button
          type='submit'
          className='btn btn-success mt-5'
          disabled={loading}
        >
          {loading ? "Submitting..." : "Submit"}
        </button>
      </form>
    </div>
  );
};

export default CoworkingForm;
