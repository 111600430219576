import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";

export default function Header() {
  const navigate = useNavigate();
  const [islogin, setIslogin] = useState(localStorage.getItem("islogin"));
  const handlelogout = () => {
    localStorage.clear();
    navigate("/");
  };
  return (
    <>
      <div id='header'>
        <nav className='header-top bg-secondary py-1'>
          <div className='container padding-side'>
            <div className='d-flex flex-wrap justify-content-between align-items-center'>
              <ul className='info d-flex flex-wrap list-unstyled m-0'>
                <li
                  className='location text-capitalize d-flex align-items-center me-4'
                  style={{ fontSize: "14px" }}
                >
                  <svg className='color me-1' width='15' height='15'>
                    <use xlinkHref='#location' />
                  </svg>
                  Jaipur(Rajasthan)
                </li>
                <li
                  className='phone d-flex align-items-center me-4'
                  style={{ fontSize: "14px" }}
                >
                  <a href='tel:+919116109415' target='_blank'>
                    <svg className='color me-1' width='15' height='15'>
                      <use xlinkHref='#phone'></use>
                    </svg>
                    +91 9116109415
                  </a>
                </li>
                <li
                  className='time d-flex align-items-center me-4'
                  style={{ fontSize: "14px" }}
                >
                  <svg className='color me-1' width='15' height='15'>
                    <use xlinkHref='#email'></use>
                  </svg>
                  info@invisispace.com
                </li>
              </ul>
              <ul className='social-links d-flex flex-wrap list-unstyled m-0 '>
                <li className='social'>
                  <a href='https://www.facebook.com/profile.php?id=100090868626575'>
                    <svg className='social' width='16' height='16'>
                      <use xlinkHref='#facebook'></use>
                    </svg>
                  </a>
                </li>
                <li className='social ms-4'>
                  <a href='https://x.com/DheerajJai72798'>
                    <svg className='social' width='16' height='16'>
                      <use xlinkHref='#twitter'></use>
                    </svg>
                  </a>
                </li>
                <li className='social ms-4'>
                  <a href='https://www.linkedin.com/in/invisispace-workspace-on-demand-9350b0135/'>
                    <svg className='social' width='16' height='16'>
                      <use xlinkHref='#linkedin'></use>
                    </svg>
                  </a>
                </li>
                <li className='social ms-4'>
                  <a href='https://www.instagram.com/invisispacejpr/'>
                    <svg className='social' width='16' height='16'>
                      <use xlinkHref='#instagram'></use>
                    </svg>
                  </a>
                </li>
                {/* <li className='social ms-4'>
                  <a href='#'>
                    <svg className='social' width='16' height='16'>
                      <use xlinkHref='#youtube'></use>
                    </svg>
                  </a>
                </li> */}
              </ul>
            </div>
          </div>
        </nav>
        <nav id='primary-header' className='navbar navbar-expand-lg py-4'>
          <div className='container padding-side'>
            <div className='d-flex justify-content-between align-items-center w-100'>
              <a className='navbar-brand w-50' href='/'>
                <img
                  src='images/invisispacelogo.png'
                  className='logo img-fluid w-50'
                />
              </a>
              <button
                className='navbar-toggler border-0 d-flex d-lg-none order-3 p-2 shadow-none'
                type='button'
                data-bs-toggle='offcanvas'
                data-bs-target='#bdNavbar'
                aria-controls='bdNavbar'
                aria-expanded='false'
              >
                <svg className='navbar-icon' width='60' height='60'>
                  <use xlinkHref='#navbar-icon'></use>
                </svg>
              </button>
              <div
                className='header-bottom offcanvas offcanvas-end '
                id='bdNavbar'
                aria-labelledby='bdNavbarOffcanvasLabel'
              >
                <div className='offcanvas-header px-4 pb-0'>
                  <button
                    type='button'
                    className='btn-close btn-close-black mt-2'
                    data-bs-dismiss='offcanvas'
                    aria-label='Close'
                    data-bs-target='#bdNavbar'
                  ></button>
                </div>
                <div className='offcanvas-body align-items-center justify-content-center'>
                  <div className='search d-block d-lg-none m-5'>
                    {/* <form className=' position-relative'>
                      <input
                        type='text'
                        className='form-control bg-secondary border-0 rounded-5 px-4 py-2'
                        placeholder='Search...'
                      />
                      <a
                        href='#'
                        className='position-absolute top-50 end-0 translate-middle-y p-1 me-3'
                      >
                        <svg className='' width='20' height='20'>
                          <use xlinkHref='#search'></use>
                        </svg>
                      </a>
                    </form> */}

                    {islogin == "true" ? (
                      <button
                        aria-current='page'
                        className='btn btn-danger'
                        onClick={handlelogout}
                      >
                        Logout
                      </button>
                    ) : (
                      <NavLink
                        className='btn btn-primary'
                        aria-current='page'
                        to='/login'
                      >
                        Login
                      </NavLink>
                    )}
                  </div>
                  <ul className='navbar-nav align-items-center mb-2 mb-lg-0'>
                    <li className='nav-item px-3'>
                      <NavLink
                        className='nav-link p-0'
                        aria-current='page'
                        to='/'
                      >
                        Home
                      </NavLink>
                    </li>
                    {/* <li className='nav-item px-3'>
                      <NavLink className='nav-link p-0' to='/about'>
                        About
                      </NavLink>
                    </li> */}
                    <li className='nav-item px-3'>
                      <NavLink className='nav-link p-0' to='/services'>
                        Services
                      </NavLink>
                    </li>
                    <li className='nav-item px-3'>
                      <NavLink className='nav-link p-0' to='/spaces'>
                        Spaces
                      </NavLink>
                    </li>
                    <li className='nav-item px-3'>
                      <NavLink className='nav-link p-0' to='/contact'>
                        Contact
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div>
              <div className='search d-lg-block d-none'>
                {/* <form className=' position-relative'>
                
                  <input
                    type='text'
                    className='form-control bg-secondary border-0 rounded-5 px-4 py-2'
                    placeholder='Search...'
                  />
                  <a
                    href='#'
                    className='position-absolute top-50 end-0 translate-middle-y p-1 me-3'
                  >
                    <svg className='' width='20' height='20'>
                      <use xlinkHref='#search'></use>
                    </svg>
                  </a>
                </form> */}
                {islogin == "true" ? (
                  <button
                    aria-current='page'
                    className='btn btn-danger'
                    onClick={handlelogout}
                  >
                    Logout
                  </button>
                ) : (
                  <NavLink
                    className='btn btn-primary'
                    aria-current='page'
                    to='/login'
                  >
                    Login
                  </NavLink>
                )}
              </div>
            </div>
          </div>
        </nav>
      </div>
    </>
  );
}
