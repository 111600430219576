import React from "react";
import Footer from "../component/Footer";
import Header from "../component/Header";

export default function AdminPage() {
  return (
    <>
      <Header />
      <h1>AdminPage</h1>
      <Footer />
    </>
  );
}
