import React, { useEffect } from "react";

export default function Services() {
  useEffect(() => {
    const page = window.location.pathname.toLocaleString();
    console.log(page);
  }, []);
  return (
    <>
      <div id='services' className='padding-medium '>
        <div className='container-fluid padding-side' data-aos='fade-up'>
          <h3 className='display-3 text-center fw-normal col-lg-4 offset-lg-4'>
            Our services & facilities
          </h3>
          <div className='row mt-5'>
            <div className='col-md-6 col-xl-4'>
              <div className='service mb-4 text-center rounded-4 p-5'>
                <div className='position-relative'>
                  <svg className='color' width='70' height='70'>
                    <use xlinkHref='#Space-Tour'></use>
                  </svg>
                  <img
                    src='images/pattern2.png'
                    alt='img'
                    className='position-absolute top-100 start-50 translate-middle z-n1 pe-5'
                  />
                </div>
                <h4 className='display-6 fw-normal my-3'>Space tour</h4>
                <p>
                  Take a Space Tour with InvisiSpace and explore our range of
                  modern office spaces. From private offices to collaborative
                  work areas, our spaces are designed for productivity and
                  flexibility. See firsthand how our stylish, fully equipped
                  environments can support your team and business goals. Book
                  your tour today!
                </p>
                {window.location.pathname.toLowerCase() ===
                "/services" ? null : (
                  <a href='/services' className='btn btn-arrow'>
                    <span className='text-decoration-underline'>
                      Read More
                      <svg width='18' height='18'>
                        <use xlinkHref='#arrow-right'></use>
                      </svg>
                    </span>
                  </a>
                )}
              </div>
            </div>

            <div className='col-md-6 col-xl-4'>
              <div className='service mb-4 text-center rounded-4 p-5'>
                <div className='position-relative'>
                  <svg className='color' width='70' height='70'>
                    <use xlinkHref='#Space-Shortlisting'></use>
                  </svg>
                  <img
                    src='images/pattern2.png'
                    alt='img'
                    className='position-absolute top-100 start-50 translate-middle z-n1 pe-5'
                  />
                </div>
                <h4 className='display-6 fw-normal my-3'>Space Shortlisting</h4>
                <p>
                  With InvisiSpace, finding the perfect office space is simple.
                  Our Space Shortlisting service helps you filter options based
                  on your budget, location, and specific needs. Quickly narrow
                  down your choices to find the ideal workspace that fits your
                  business requirements and style.
                </p>
                {window.location.pathname.toLowerCase() ===
                "/services" ? null : (
                  <a href='/services' className='btn btn-arrow'>
                    <span className='text-decoration-underline'>
                      Read More
                      <svg width='18' height='18'>
                        <use xlinkHref='#arrow-right'></use>
                      </svg>
                    </span>
                  </a>
                )}
              </div>
            </div>

            <div className='col-md-6 col-xl-4'>
              <div className='service mb-4 text-center rounded-4 p-5'>
                <div className='position-relative'>
                  <svg className='color' width='70' height='70'>
                    <use xlinkHref='#Price-Negotiation'></use>
                  </svg>
                  <img
                    src='images/pattern2.png'
                    alt='img'
                    className='position-absolute top-100 start-50 translate-middle z-n1 pe-5'
                  />
                </div>
                <h4 className='display-6 fw-normal my-3'>Price negotiation</h4>
                <p>
                  At InvisiSpace, we offer flexible Price Negotiation to ensure
                  you get the best deal. Our team works closely with you to find
                  a pricing plan that suits your budget, without compromising on
                  quality. Let us help you secure the perfect workspace at a
                  price that works for your business.
                </p>
                {window.location.pathname.toLowerCase() ===
                "/services" ? null : (
                  <a href='/services' className='btn btn-arrow'>
                    <span className='text-decoration-underline'>
                      Read More
                      <svg width='18' height='18'>
                        <use xlinkHref='#arrow-right'></use>
                      </svg>
                    </span>
                  </a>
                )}
              </div>
            </div>

            <div className='col-md-6 col-xl-4'>
              <div className='service mb-4 text-center rounded-4 p-5'>
                <div className='position-relative'>
                  <svg className='color' width='70' height='70'>
                    <use xlinkHref='#Contract-Signing'></use>
                  </svg>
                  <img
                    src='images/pattern2.png'
                    alt='img'
                    className='position-absolute top-100 start-50 translate-middle z-n1 pe-5'
                  />
                </div>
                <h4 className='display-6 fw-normal my-3'>Timely Handover</h4>
                <p>
                  At InvisiSpace, we pride ourselves on a Timely Handover of
                  your office space. Our team ensures that your space is ready
                  on schedule, so you can start your operations without delay.
                  Move in with confidence, knowing everything will be set up and
                  prepared for you on time.
                </p>
                {window.location.pathname.toLowerCase() ===
                "/services" ? null : (
                  <a href='/services' className='btn btn-arrow'>
                    <span className='text-decoration-underline'>
                      Read More
                      <svg width='18' height='18'>
                        <use xlinkHref='#arrow-right'></use>
                      </svg>
                    </span>
                  </a>
                )}
              </div>
            </div>

            <div className='col-md-6 col-xl-4'>
              <div className='service mb-4 text-center rounded-4 p-5'>
                <div className='position-relative'>
                  <svg className='color' width='70' height='70'>
                    <use xlinkHref='#Timely-Handover'></use>
                  </svg>
                  <img
                    src='images/pattern2.png'
                    alt='img'
                    className='position-absolute top-100 start-50 translate-middle z-n1 pe-5'
                  />
                </div>
                <h4 className='display-6 fw-normal my-3'>Contract Signing</h4>
                <p>
                  The Contract Signing process with InvisiSpace is
                  straightforward and transparent. Once you’ve selected your
                  ideal space, our team ensures that all terms are clear and
                  that you’re comfortable with the agreement. We guide you
                  through every step to ensure a smooth, hassle-free signing
                  experience.
                </p>
                {window.location.pathname.toLowerCase() ===
                "/services" ? null : (
                  <a href='/services' className='btn btn-arrow'>
                    <span className='text-decoration-underline'>
                      Read More
                      <svg width='18' height='18'>
                        <use xlinkHref='#arrow-right'></use>
                      </svg>
                    </span>
                  </a>
                )}
              </div>
            </div>

            <div className='col-md-6 col-xl-4'>
              <div className='service mb-4 text-center rounded-4 p-5'>
                <div className='position-relative'>
                  <svg className='color' width='70' height='70'>
                    <use xlinkHref='#Onboarding-Settling'></use>
                  </svg>
                  <img
                    src='images/pattern2.png'
                    alt='img'
                    className='position-absolute top-100 start-50 translate-middle z-n1 pe-5'
                  />
                </div>
                <h4 className='display-6 fw-normal my-3'>
                  Onboarding and Settling
                </h4>
                <p>
                  Our Onboarding and Settling process at InvisiSpace is designed
                  to help you get started quickly. We provide support every step
                  of the way, from setting up your workspace to familiarizing
                  your team with the facilities. Enjoy a smooth transition as
                  you settle into your new office.
                </p>
                {window.location.pathname.toLowerCase() ===
                "/services" ? null : (
                  <a href='/services' className='btn btn-arrow'>
                    <span className='text-decoration-underline'>
                      Read More
                      <svg width='18' height='18'>
                        <use xlinkHref='#arrow-right'></use>
                      </svg>
                    </span>
                  </a>
                )}
              </div>
            </div>
          </div>
          <h3 className='display-3 my-5 text-center fw-normal col-lg-4 offset-lg-4'>
            F & Q
          </h3>
          <div className='row accordion' id='faqAccordion'>
            <div className='accordion-item'>
              <h2 className='accordion-header' id='headingOne'>
                <button
                  className='accordion-button'
                  type='button'
                  data-bs-toggle='collapse'
                  data-bs-target='#collapseOne'
                  aria-expanded='true'
                  aria-controls='collapseOne'
                >
                  What is Virtua office
                </button>
              </h2>
              <div
                id='collapseOne'
                className='accordion-collapse collapse show'
                aria-labelledby='headingOne'
                data-bs-parent='#faqAccordion'
              >
                <div className='accordion-body text-justify'>
                  A virtual office is a service designed to offer businesses a
                  physical address and essential business services, all without
                  the need for a dedicated physical office space. This
                  innovative solution enables businesses to operate remotely
                  while upholding a polished and professional image.
                  <br /> <br />
                  In a virtual office arrangement, the provided virtual address
                  serves as the official business address, with the added
                  convenience of mail and packages being forwarded to a
                  designated location. Additionally, virtual offices extend
                  services such as phone and fax handling, alongside access to
                  rentable meeting rooms and conference facilities on a
                  need-by-need basis.
                  <br />
                  <br /> Ideal for startups, freelancers, and remote workers,
                  virtual offices empower them to maintain a credible
                  professional presence minus the financial and long-term
                  commitment tied to traditional office spaces. The flexibility
                  and accessibility inherent in virtual offices allow businesses
                  to access these services from virtually any location
                  worldwide, making them a valuable asset in the modern business
                  landscape.
                </div>
              </div>
            </div>

            <div className='accordion-item'>
              <h2 className='accordion-header' id='headingTwo'>
                <button
                  className='accordion-button'
                  type='button'
                  data-bs-toggle='collapse'
                  data-bs-target='#collapseTwo'
                  aria-expanded='false'
                  aria-controls='collapseTwo'
                >
                  What are the services of a virtual office
                </button>
              </h2>
              <div
                id='collapseTwo'
                className='accordion-collapse collapse'
                aria-labelledby='headingTwo'
                data-bs-parent='#faqAccordion'
              >
                <div className='accordion-body'>
                  Following are the services of virtual offices in India:
                  <ul>
                    <li>
                      <b>Professional Business Address:</b> Essentially, a
                      virtual office is an actual address that you may use for
                      business registration, correspondence, and other official
                      purposes (including GST registration and legal
                      registration), usually in a premium commercial area.
                    </li>
                    <br />
                    <li>
                      <b>Mail Handling:</b> Receiving and forwarding mail is a
                      common virtual office service. Your business mail can be
                      delivered to the virtual office address, and the provider
                      can either send it to the address of your choice or
                      arrange for pick-up.
                    </li>
                    <br />
                    <li>
                      <b>Phone and Reception Services:</b> Some virtual office
                      providers offer phone answering services, where a
                      professional receptionist answers calls for your company
                      under your name. There might also be call forwarding or
                      voicemail options available.
                    </li>
                    <br />
                    <li>
                      <b>Meeting Rooms and Conference Rooms:</b> While a virtual
                      office itself does not provide a physical workspace, many
                      providers offer access to meeting rooms or conference
                      spaces that can be rented on an as-needed basis.
                    </li>
                    <br />
                    <li>
                      <b>Administrative Support:</b> Some virtual office
                      services also provide administrative support, such as
                      handling customer inquiries, scheduling, or document
                      preparation.
                    </li>
                    <br />
                    <li>
                      <b>Remote Work Flexibility:</b> With the help of virtual
                      offices, companies may operate remotely, giving workers or
                      company owners the freedom to work from any location while
                      yet projecting a professional image in a certain nation.
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className='accordion-item'>
              <h2 className='accordion-header' id='headingThree'>
                <button
                  className='accordion-button'
                  type='button'
                  data-bs-toggle='collapse'
                  data-bs-target='#collapseThree'
                  aria-expanded='false'
                  aria-controls='collapseThree'
                >
                  Why do businesses need a virtual office address
                </button>
              </h2>
              <div
                id='collapseThree'
                className='accordion-collapse collapse'
                aria-labelledby='headingThree'
                data-bs-parent='#faqAccordion'
              >
                <div className='accordion-body'>
                  A virtual office address is important for each company looking
                  for expanding their business or seeking GST registration. The
                  business address also has some other uses, as mentioned in the
                  following:
                  <br />
                  <ul>
                    <li>
                      To project a polished and professional brand image on
                      business cards, letterheads, websites, and other corporate
                      materials, ensuring credibility and trustworthiness in the
                      eyes of clients and partners.
                    </li>
                    <br />
                    <li>
                      Build customer confidence by establishing a strong
                      presence in multiple locations, showcasing reliability,
                      accessibility, and a commitment to serving clients
                      wherever they are.
                    </li>
                    <br />
                    <li>
                      Efficiently manage calls and couriers across multiple
                      geographical locations, ensuring seamless communication
                      and reliable handling of business operations, regardless
                      of location.
                    </li>
                    <br />
                    <li>
                      Simplify the process of applying for state-wise GST
                      registrations, enabling businesses to comply with regional
                      tax regulations and streamline operations across multiple
                      states.
                    </li>
                    <br />
                  </ul>
                </div>
              </div>
            </div>

            <div className='accordion-item'>
              <h2 className='accordion-header' id='headingFour'>
                <button
                  className='accordion-button'
                  type='button'
                  data-bs-toggle='collapse'
                  data-bs-target='#collapseFour'
                  aria-expanded='false'
                  aria-controls='collapseFour'
                >
                  Where can I book a virtual office space
                </button>
              </h2>
              <div
                id='collapseFour'
                className='accordion-collapse collapse'
                aria-labelledby='headingFour'
                data-bs-parent='#faqAccordion'
              >
                <div className='accordion-body'>
                  The concept of virtual offices is rapidly gaining momentum in
                  India, offering businesses the flexibility and efficiency they
                  need in today’s dynamic work environment. Instead of spending
                  countless hours researching suitable spaces, professionals and
                  businesses can streamline their search with a flex marketplace
                  like Invisispace, Invisispace stands as India’s largest
                  tech-enabled platform for booking office spaces, catering to a
                  wide array of needs—from private offices and managed
                  workspaces to Coworking spaces. With access to over 500
                  centers across 30+ cities, Invisispace ensures a seamless
                  experience, free from the hassle of brokerage fees. One of
                  Invisispace standout features is its ability to consolidate
                  all your workspace requirements into a single billing system
                  for multiple cities, coupled with streamlined invoicing,
                  making it ideal for businesses with pan-India operations.
                  Whether you’re a startups, a freelancer, or an enterprise,
                  Invisispace provides a hassle-free way to find the perfect
                  workspace while saving both time and money. Embrace
                  flexibility and convenience with Invisispace and unlock the
                  potential of smart workspace solutions tailored to your needs.
                </div>
              </div>
            </div>

            <div className='accordion-item'>
              <h2 className='accordion-header' id='headingFive'>
                <button
                  className='accordion-button'
                  type='button'
                  data-bs-toggle='collapse'
                  data-bs-target='#collapseFive'
                  aria-expanded='false'
                  aria-controls='collapseFive'
                >
                  How much does a virtual office cost
                </button>
              </h2>
              <div
                id='collapseFive'
                className='accordion-collapse collapse'
                aria-labelledby='headingFive'
                data-bs-parent='#faqAccordion'
              >
                <div className='accordion-body'>
                  The prices of virtual office spaces differ as per the location
                  and the add-on services. However, the average cost of
                  attaining a virtual is 15,000-40,000 yearly.
                </div>
              </div>
            </div>

            <div className='accordion-item'>
              <h2 className='accordion-header' id='headingSix'>
                <button
                  className='accordion-button'
                  type='button'
                  data-bs-toggle='collapse'
                  data-bs-target='#collapseSix'
                  aria-expanded='false'
                  aria-controls='collapseSix'
                >
                  Are virtual offices legal in India
                </button>
              </h2>
              <div
                id='collapseSix'
                className='accordion-collapse collapse'
                aria-labelledby='headingSix'
                data-bs-parent='#faqAccordion'
              >
                <div className='accordion-body'>
                  Yes, virtual offices are legal in India. Businesses can use
                  virtual offices for company registration, GST registration,
                  and legal correspondence. They provide a business address
                  without the need for physical office space, making them a
                  cost-effective and flexible option.
                </div>
              </div>
            </div>

            <div className='accordion-item'>
              <h2 className='accordion-header' id='headingSeven'>
                <button
                  className='accordion-button'
                  type='button'
                  data-bs-toggle='collapse'
                  data-bs-target='#collapseSeven'
                  aria-expanded='false'
                  aria-controls='collapseSeven'
                >
                  The Importance of a Virtual Office for Business and GST
                  Registration
                </button>
              </h2>
              <div
                id='collapseSeven'
                className='accordion-collapse collapse'
                aria-labelledby='headingSeven'
                data-bs-parent='#faqAccordion'
              >
                <div className='accordion-body'>
                  Today a physical office is optional to register your business.
                  However, on the other hand, an office address is essential for
                  business registration or while forming a company. You can
                  register your business with the state's nearest GST and
                  municipal authorities if you have a virtual office address.{" "}
                  <br />
                  <br />
                  You should have a registered office address. Now, it's time to
                  take advantage of the most cost-effective and professional
                  Virtual Office in a premium location in India without paying
                  exorbitant rent in every state where you want to register for
                  GST. <br />
                  <br />
                  However, maintaining an office in every state is impractical.
                  Still, a Virtual Office can help you to solve the issue and
                  meet all the legal criteria for GST registration in regions
                  where you do not have a physical/local presence. As a result,
                  Virtual Office is an essential tool for GST Registration.{" "}
                  <br />
                  <br />
                  So, is GST Registration viable with a virtual office in India?
                  The answer is yes; the virtual office world is entirely legal
                  in India. It is mainly popular among e-commerce sellers or
                  service providers such as Flipkart, Amazon, Meesho, Snapdeal,
                  and many more firms that work from anywhere. As we all know,
                  GST is required for online sales through e-commerce operators;
                  virtual offices are a golden solution.
                </div>
              </div>
            </div>

            <div className='accordion-item'>
              <h2 className='accordion-header' id='headingEight'>
                <button
                  className='accordion-button'
                  type='button'
                  data-bs-toggle='collapse'
                  data-bs-target='#collapseEight'
                  aria-expanded='false'
                  aria-controls='collapseEight'
                >
                  Are you planning to have a virtual office for GST registration
                  in India
                </button>
              </h2>
              <div
                id='collapseEight'
                className='accordion-collapse collapse'
                aria-labelledby='headingEight'
                data-bs-parent='#faqAccordion'
              >
                <div className='accordion-body'>
                  Are you considering the establishment of a virtual office for
                  GST registration in India? If your answer is yes, your search
                  ends here – you've come to the right place. It's time to take
                  advantage of the most cost-effective and professional Virtual
                  Office in a premium location in India without paying
                  exorbitant rent. We have all your choices for Company
                  Registration or GST Registration Team Cowork has the right
                  solution for you for your business. <br />
                  <br />
                  Virtual offices are a viable alternative for firms in India
                  that wish to reduce overhead costs and work more freely. While
                  there are requirements that firms must follow when using
                  virtual offices for GST registration, virtual offices can be
                  used as the registered office address for Tax purposes.
                  Businesses that collaborate with a virtual office service
                  provider can receive access to a variety of services, such as
                  a real address, mail handling, and phone answering services,
                  which can help them run more smoothly. <br />
                  <br />
                  Businesses in all over India might profit greatly from virtual
                  office service providers. These service providers can provide
                  a variety of services to assist firms to build a professional
                  image, such as physical address, mail handling, and phone
                  answering services. Furthermore, virtual office service
                  providers can assist organizations in running more smoothly by
                  giving access to meeting rooms, co-working spaces, and other
                  amenities. <br />
                  <br />
                  Virtual offices are an excellent option for businesses looking
                  to establish a professional presence and achieve their goals
                  pan India, whether it be obtaining GST registration, expanding
                  their customer base, or increasing their brand visibility.
                  With Team Cowork, businesses can enjoy the benefits of a
                  virtual office without compromising on quality or convenience.{" "}
                  <br />
                  <br />
                  Nevertheless, virtual offices can be a beneficial tool for
                  India firms, particularly those wishing to reduce overhead
                  costs and work more flexibly. By connecting with Team Cowork,
                  your company can receive access to a variety of services that
                  can help it run more smoothly and project a professional
                  image.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
