import React, { useState } from "react";
import Header from "../component/Header";
import Footer from "../component/Footer";
import axios from "axios";
import { useNavigate } from "react-router-dom";

export default function LoginPage() {
  // State to hold form data
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const navigate = useNavigate();
  // Handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!username || !password) {
      setError("Please fill in both fields");
      return;
    }
    //let username = email;

    // try {
    //   const response = await fetch(
    //     `${process.env.REACT_APP_APP_SERVER}/api/login`,
    //     {
    //       method: "POST",
    //       headers: {
    //         "Content-Type": "application/json",
    //       },
    //       body: JSON.stringify({ username, password }),
    //     }
    //   );

    //   const data = await response.json();
    //   console.log(data.message.toLowerCase());

    //   if (data.message.toLowerCase() === "login successful") {
    //     setSuccessMessage(data.message);
    //     console.log(data.login[0].usertype);

    //     if (data.login[0].usertype === "user") {
    //       navigate("/client");
    //       localStorage.setItem("islogin", true);
    //       localStorage.setItem("user", "client");
    //     } else {
    //       navigate("/admin");
    //     }

    //     setError("");
    //   } else {
    //     setError(data.message);
    //     setSuccessMessage("");
    //   }

    //   // Reset the form after submission
    //   setUsername("");
    //   setPassword("");
    // } catch (error) {
    //   console.error("Error during login:", error);
    //   setError("Something went wrong. Please try again.");
    //   setSuccessMessage("");
    // }
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_APP_SERVER}/api/login`,
        {
          username,
          password,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const data = response.data;

      if (data.message.toLowerCase() === "login successful") {
        setSuccessMessage(data.message);

        if (data.login[0].usertype.toLowerCase() === "user") {
          navigate("/client");
          localStorage.setItem("islogin", "true");
          localStorage.setItem("user", "client");
        } else {
          navigate("/admin");
        }

        setError("");
      } else {
        setError(data.message);
        setSuccessMessage("");
      }

      // Reset the form after submission
      setUsername("");
      setPassword("");
    } catch (error) {
      console.error("Error during login:", error);
      setError("Something went wrong. Please try again.");
      setSuccessMessage("");
    }
  };

  return (
    <>
      <Header />
      <div className='container-fluid mt-4  align-items-center'>
        <div className='row d-flex justify-content-center'>
          <div className='col-12 col-md-6 col-lg-4'>
            <div className='card shadow-lg border-0'>
              <div className='card-body p-4'>
                <h3 className='text-center mb-4'>Login</h3>

                {error && <div className='alert alert-danger'>{error}</div>}
                {successMessage && (
                  <div className='alert alert-success'>{successMessage}</div>
                )}

                <form onSubmit={handleSubmit}>
                  <div className='mb-3'>
                    <label htmlFor='formEmail' className='form-label'>
                      Username
                    </label>
                    <input
                      type='text'
                      className='form-control'
                      id='formEmail'
                      placeholder='Enter your username'
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                      required
                    />
                  </div>

                  <div className='mb-3'>
                    <label htmlFor='formPassword' className='form-label'>
                      Password
                    </label>
                    <input
                      type='password'
                      className='form-control'
                      id='formPassword'
                      placeholder='Enter your password'
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      required
                    />
                  </div>

                  <button type='submit' className='btn btn-primary w-100'>
                    Log In
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
