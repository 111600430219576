import React from "react";
import Modal from "react-modal";
import InquiryForm from "../component/InquiryForm";
export default function modal({ isOpen, closeModal }) {
  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        contentLabel='Enquiry Form'
        ariaHideApp={false} // Required for accessibility
        style={{
          content: {
            width: "100%", // Set modal width to 80%
            maxWidth: "80%", // Ensure the modal doesn't grow beyond 80%
            height: "98%", // Allow height to be flexible
            margin: "auto", // Center the modal horizontally
            padding: "0", // Remove padding for precise control
            overflow: "scroll", // Hide the scrollbar if content overflows
            borderRadius: "8px", // Optional: Add border radius for rounded corners
            backgroundColor: "",
          },
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)", // Optional: Semi-transparent dark background
            zIndex: 9999, // Ensure the modal is on top of other content
          },
        }}
      >
        <div className='modal-content rounded'>
          <div className='modal-header'>
            <h5 className='modal-title'></h5>
            <button
              type='button'
              className='btn-close m-3'
              onClick={closeModal}
              aria-label='Close'
            ></button>
          </div>
          <div className='modal-body'>
            <div className='container'>
              <div className='row'>
                <div className='col-md-6'>
                  <img
                    src='images/img6.png'
                    width='100%'
                    className='img-fluid mt-5 pt-4'
                    style={{
                      borderRadius: "10%",
                    }}
                  />
                </div>
                <div className='col-md-6 my-2'>
                  <InquiryForm />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}
