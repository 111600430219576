import React, { useState } from "react";
import emailjs from "emailjs-com";

export default function InquiryForm() {
  // Define state variables for form data
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    contact: "",
    custom: "",
    enquirePlan: "",
  });

  // Define state for form validation errors
  const [errors, setErrors] = useState({
    name: "",
    email: "",
    contact: "",
    custom: "",
    enquirePlan: "",
  });

  // State to control the visibility of the custom input field
  const [dis, setDis] = useState("none");

  // Handle form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // If the "Custom" option is selected, show the custom input field
    if (name === "enquirePlan" && value === "other") {
      setDis("block"); // Show the custom input field
    } else if (name === "enquirePlan" && value !== "other") {
      setDis("none"); // Hide the custom input field
    }

    // Update form data
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Form validation
  const validateForm = () => {
    const newErrors = {};
    let isValid = true;

    // Name validation
    if (!formData.name) {
      newErrors.name = "Name is required";
      isValid = false;
    }

    // Email validation
    if (!formData.email) {
      newErrors.email = "Email is required";
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Email is invalid";
      isValid = false;
    }

    // Contact number validation
    if (!formData.contact) {
      newErrors.contact = "Contact number is required";
      isValid = false;
    } else if (!/^\d{10}$/.test(formData.contact)) {
      newErrors.contact = "Contact number must be 10 digits";
      isValid = false;
    }

    // Enquire Plan validation
    if (!formData.enquirePlan) {
      newErrors.enquirePlan = "Please select an enquiry plan";
      isValid = false;
      if (formData.enquirePlan === "other") {
        // Custom validation
        if (!formData.custom) {
          newErrors.custom = "Custom enquiry is required";
          isValid = false;
        }
      }
    }

    setErrors(newErrors);
    return isValid;
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    // If form is valid, send the email
    if (validateForm()) {
      try {
        const subject = `Inquiry from ${formData.name}`;
        const message = `
  Name: ${formData.name}
  Email: ${formData.email}
  Contact: ${formData.contact}
  Enquire: ${
    formData.enquirePlan === "other" ? formData.custom : formData.enquirePlan
  }
`;

        // Create a message object that matches the fields in your EmailJS template
        const emailParams = {
          from_name: formData.name,
          from_email: formData.email,
          contact: formData.contact,
          enquire_plan: formData.enquirePlan
            ? formData.enquirePlan
            : formData.custom,
          message: message,
        };

        // Send email using the EmailJS service
        emailjs
          .send(
            "service_301hyk8", // Your service ID
            "template_mrolkp1", // Your template ID
            emailParams, // The email parameters (including the custom message)
            "p1oDkEwh7tANcx3V_" // Your user ID
          )
          .then(
            (result) => {
              console.log("Email sent successfully:", result.text);
              alert("Your message has been sent!");
              // Reset the form after submission
              setFormData({
                name: "",
                email: "",
                contact: "",
                custom: "",
                enquirePlan: "",
              });
            },
            (error) => {
              console.error("Error sending email:", error.text);
              alert("There was an error sending your message.");
            }
          );

        // const response = await fetch(
        //   `${process.env.REACT_APP_APP_SERVER}/api/sendmail`,
        //   {
        //     method: "POST",
        //     headers: {
        //       "Content-Type": "application/json",
        //     },
        //     body: JSON.stringify({
        //       subject: subject,
        //       text: message,
        //     }),
        //   }
        // );

        // if (response.ok) {
        //   alert("Form submitted successfully!");
        //   console.log("Email sent:", await response.json());
        //   // Clear form after successful submission
        //   setFormData({
        //     name: "",
        //     email: "",
        //     contact: "",
        //     enquirePlan: "",
        //     custom: "",
        //   });
        //   setErrors({});
        // } else {
        //   const errorData = await response.json();
        //   alert(`Error: ${errorData.message || "Something went wrong"}`);
        // }
      } catch (error) {
        console.error("Error submitting form:", error);
        alert("An error occurred. Please try again later.");
      }
    }
  };

  return (
    <div className='container mt-4'>
      <h2 className='text-center mb-4'>Enquiry Form</h2>
      <form onSubmit={handleSubmit}>
        {/* Name */}
        <div className='mb-3'>
          <label htmlFor='name' className='form-label'>
            Name
          </label>
          <input
            type='text'
            id='name'
            name='name'
            className='form-control'
            value={formData.name}
            onChange={handleInputChange}
            required
          />
          {errors.name && <div className='text-danger'>{errors.name}</div>}
        </div>

        {/* Email */}
        <div className='mb-3'>
          <label htmlFor='email' className='form-label'>
            Email ID
          </label>
          <input
            type='email'
            id='email'
            name='email'
            className='form-control'
            value={formData.email}
            onChange={handleInputChange}
            required
          />
          {errors.email && <div className='text-danger'>{errors.email}</div>}
        </div>

        {/* Contact Number */}
        <div className='mb-3'>
          <label htmlFor='contact' className='form-label'>
            Contact Number
          </label>
          <input
            type='text'
            id='contact'
            name='contact'
            className='form-control'
            value={formData.contact}
            onChange={handleInputChange}
            required
          />
          {errors.contact && (
            <div className='text-danger'>{errors.contact}</div>
          )}
        </div>

        {/* Enquire Plan */}
        <div className='mb-3'>
          <label htmlFor='enquirePlan' className='form-label'>
            Enquire About
          </label>
          <select
            id='enquirePlan'
            name='enquirePlan'
            className='form-select'
            value={formData.enquirePlan}
            onChange={handleInputChange}
            required
          >
            <option value=''>--Select Plan--</option>
            <option value='Mail Handling'>Mail Handling</option>
            <option value='GST Registration'>GST Registration</option>
            <option value='Business Registration'>Business Registration</option>
            <option value='GST Registration with Seat Number'>
              GST Registration with Seat Number
            </option>
            <option value='Business Registration with Seat Number'>
              Business Registration with Seat Number
            </option>
            <option value='Hot Desk'>Hot Desk</option>
            <option value='Dedicated Desk'>Dedicated Desk</option>
            <option value='2 Seater Cabin'>2 Seater Cabin</option>
            <option value='3 Seater Cabin'>3 Seater Cabin</option>
            <option value='4 Seater Cabin'>4 Seater Cabin</option>
            <option value='5 Seater Cabin'>5 Seater Cabin</option>
            <option value='6 Seater Cabin'>6 Seater Cabin</option>
            <option value='7 Seater Cabin'>7 Seater Cabin</option>
            <option value='8 Seater Cabin'>8 Seater Cabin</option>
            <option value='10 Seater Cabin'>10 Seater Cabin</option>
            <option value='12 Seater Cabin'>12 Seater Cabin</option>
            <option value='other'>Custom (Add Lines as per Requirement)</option>
          </select>

          {errors.enquirePlan && (
            <div className='text-danger'>{errors.enquirePlan}</div>
          )}
        </div>

        {/* Custom */}
        <div className='mb-3' style={{ display: dis }}>
          <label htmlFor='custom' className='form-label'>
            Custom Enquiry
          </label>
          <input
            type='text'
            id='custom'
            name='custom'
            className='form-control'
            value={formData.custom}
            onChange={handleInputChange}
            required={formData.enquirePlan === "other"}
          />
          {errors.custom && <div className='text-danger'>{errors.custom}</div>}
        </div>

        {/* Submit Button */}
        <div className='text-center'>
          <button type='submit' className='btn bg-gray'>
            Submit
          </button>
        </div>
      </form>
    </div>
  );
}
