import React from "react";

export default function Gallery() {
  return (
    <>
      <div id='gallery' data-aos='fade-up'>
        <h3 className='display-3 fw-normal text-center'>our gallery</h3>
        <p className='text-center col-lg-4 offset-lg-4 mb-5'>
          Explore InvisiSpace's gallery to see our modern, flexible office
          spaces. From stylish workspaces to dynamic meeting rooms, discover how
          we blend productivity with design.
        </p>

        <div className='row justify-content-center'>
          <div
            id='carouselExampleControls'
            className='carousel slide col-9'
            data-bs-ride='carousel'
          >
            <div className='carousel-inner '>
              <div className='carousel-item active'>
                <img
                  src='images/ofc1.jpg'
                  alt='img'
                  className='img-fluid rounded-4'
                />
              </div>
              <div className='carousel-item'>
                <img
                  src='images/ofc2.jpg'
                  alt='img'
                  className='img-fluid rounded-4'
                />
              </div>
              <div className='carousel-item'>
                <img
                  src='images/ofc3.jpg'
                  alt='img'
                  className='img-fluid rounded-4'
                />
              </div>
            </div>
            <button
              className='carousel-control-prev btn btn-light '
              type='button'
              data-bs-target='#carouselExampleControls'
              data-bs-slide='prev'
            >
              <span
                className='carousel-control-prev-icon'
                aria-hidden='true'
              ></span>
              <span className='visually-hidden'>Previous</span>
            </button>
            <button
              className='carousel-control-next btn btn-light'
              type='button'
              data-bs-target='#carouselExampleControls'
              data-bs-slide='next'
            >
              <span
                className='carousel-control-next-icon'
                aria-hidden='true'
              ></span>
              <span className='visually-hidden'>Next</span>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

// import OwlCarousel from "react-owl-carousel";
// import "owl.carousel/dist/assets/owl.carousel.css";
// import "owl.carousel/dist/assets/owl.theme.default.css";

// const Gallery = () => {
//   return (
//     <section id='gallery' data-aos='fade-up'>
//       <h3 className='display-3 fw-normal text-center'>Our Gallery</h3>
//       <p className='text-center col-lg-4 offset-lg-4 mb-5'>
//         Explore images of our well-appointed accommodations, featuring modern
//         amenities and stylish decor designed to make your stay a memorable one.
//         Admire the stunning views of the city skyline from our rooftop pool,
//         where you can relax and unwind after a day of exploring the city.
//       </p>
//       <div className='container position-relative'>
//         <div className='row'>
//           <div className='offset-1 col-10'>
//             {/* OwlCarousel setup */}
//             <OwlCarousel
//               className='owl-theme'
//               loop
//               margin={10}
//               nav
//               responsive={{
//                 0: {
//                   items: 1,
//                 },
//                 600: {
//                   items: 2,
//                 },
//                 1000: {
//                   items: 3,
//                 },
//               }}
//             >
//               {/* Gallery Images */}
//               <div className='item'>
//                 <img
//                   src='images/item3.jpg'
//                   alt='Gallery Image 1'
//                   className='img-fluid rounded-4'
//                 />
//               </div>
//               <div className='item'>
//                 <img
//                   src='images/item2.jpg'
//                   alt='Gallery Image 2'
//                   className='img-fluid rounded-4'
//                 />
//               </div>
//               <div className='item'>
//                 <img
//                   src='images/item1.jpg'
//                   alt='Gallery Image 3'
//                   className='img-fluid rounded-4'
//                 />
//               </div>
//               <div className='item'>
//                 <img
//                   src='images/item3.jpg'
//                   alt='Gallery Image 1'
//                   className='img-fluid rounded-4'
//                 />
//               </div>
//               <div className='item'>
//                 <img
//                   src='images/item2.jpg'
//                   alt='Gallery Image 2'
//                   className='img-fluid rounded-4'
//                 />
//               </div>
//               <div className='item'>
//                 <img
//                   src='images/item1.jpg'
//                   alt='Gallery Image 3'
//                   className='img-fluid rounded-4'
//                 />
//               </div>
//             </OwlCarousel>
//           </div>
//         </div>

//         {/* Desktop navigation buttons (Optional, OwlCarousel has its own navigation) */}
//         <div className='position-absolute top-50 start-0 translate-middle-y main-slider-button-prev d-none d-md-block'>
//           <svg
//             className='bg-secondary rounded-circle p-3'
//             width='70'
//             height='70'
//           >
//             <use href='#arrow-left'></use>
//           </svg>
//         </div>
//         <div className='position-absolute top-50 end-0 translate-middle-y main-slider-button-next d-none d-md-block'>
//           <svg
//             className='bg-secondary rounded-circle p-3'
//             width='70'
//             height='70'
//           >
//             <use href='#arrow-right'></use>
//           </svg>
//         </div>

//         {/* Mobile navigation buttons */}
//         <div className='position-absolute top-100 end-50 translate-middle main-slider-button-prev mt-5 d-md-none d-block'>
//           <svg
//             className='bg-secondary rounded-circle p-2'
//             width='50'
//             height='50'
//           >
//             <use href='#arrow-left'></use>
//           </svg>
//         </div>
//         <div className='position-absolute top-100 start-50 translate-middle main-slider-button-next mt-5 ms-4 d-md-none d-block'>
//           <svg
//             className='bg-secondary rounded-circle p-2'
//             width='50'
//             height='50'
//           >
//             <use href='#arrow-right'></use>
//           </svg>
//         </div>
//       </div>
//     </section>
//   );
// };

// export default Gallery;
