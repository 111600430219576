import React, { useEffect, useState } from "react";
import Header from "../component/Header";
import Footer from "../component/Footer";
import Slider from "../component/Slider";
import Aboutus from "../component/Aboutus";
// import Info from "../component/Info";
// import Spaces from "../component/Spaces";
import Gallery from "../component/Gallery";
import Services from "../component/Services";
// import Filter from "../component/Filter";
import Modal from "../component/Modal";

export default function Home() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Open the modal after 3 seconds
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsModalOpen(true); // Open modal after 3 seconds
    }, 3000);

    // Cleanup timeout when the component unmounts
    return () => clearTimeout(timer);
  }, []);

  const closeModal = () => setIsModalOpen(false);

  return (
    <>
      <Modal isOpen={isModalOpen} closeModal={closeModal} />
      <Header />
      <Slider />
      {/* <Filter /> */}
      <Aboutus />
      {/* <Info /> */}
      {/* <Spaces /> */}
      <Gallery />
      <Services />
      <Footer />
    </>
  );
}
