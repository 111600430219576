import React, { useState } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css"; // Import OwlCarousel styles
import "owl.carousel/dist/assets/owl.theme.default.css"; // Import Owl theme styles
import Modal from "../component/Modal";
const spaces = [
  {
    imgSrc: [
      "images/locationA1.jpeg",
      "images/locationA2.jpeg",
      "images/locationA3.jpeg",
      "images/locationA4.jpeg",
      "images/locationA5.jpeg",
      "images/locationA6.jpeg",
      "images/locationA7.jpeg",
    ],
    title: "INV-JP-001",
    address: " Lalkothi, Jaipur, Rajasthan 302015",
  },
  {
    imgSrc: [
      "images/locationB1.jpeg",
      "images/locationB2.jpeg",
      "images/locationB3.jpeg",
      "images/locationB4.jpeg",
      "images/locationB5.jpeg",
      "images/locationB6.jpeg",
      "images/locationB7.jpeg",
      "images/locationB8.jpeg",
      "images/locationB9.jpeg",
      "images/locationB10.jpeg",
    ],
    title: "INV-JP-002",
    address: " Lalkothi, Jaipur, Rajasthan 302015",
  },
];

export default function Spaces() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const owlSettings = {
    loop: true,
    margin: 30,

    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 2,
      },
      1000: {
        items: 3,
      },
    },
  };

  const handleBookNow = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  return (
    <>
      <section id='room' className='padding-medium'>
        <div className='container-fluid padding-side' data-aos='fade-up'>
          <div className='d-flex flex-wrap align-items-center justify-content-between'>
            <div>
              <h3 className='display-3 fw-normal text-center'>
                Explore our Spaces
              </h3>
            </div>
            {/* <NavLink to='/spaces' className='btn btn-arrow btn-primary mt-3'>
              <span>
                Explore rooms
                <svg width='18' height='18'>
                  <use xlinkHref='#arrow-right'></use>
                </svg>
              </span>
            </NavLink> */}
          </div>
          {isModalOpen && (
            <Modal isOpen={isModalOpen} closeModal={closeModal} />
          )}
          <div className='room-swiper mt-5'>
            <OwlCarousel className='owl-theme w-100' {...owlSettings}>
              {/* {spaces.map((space, index) => (
                <div className='item' key={index}>
                  <div className='room-item position-relative bg-white rounded-4 overflow-hidden'>
                    <OwlCarousel className='owl-theme' {...owlSettings}>
                      {space.imgSrc.map((img, idx) => (
                        <img
                          key={idx}
                          src={img}
                          alt={`Co-working space - ${space.title}, location ${
                            idx + 1
                          }`}
                          className='post-image img-fluid rounded-4 bg-light'
                        />
                      ))}
                    </OwlCarousel>
                  </div>
                  <div className='p-3'>
                    <h5>{space.title}</h5>
                    <p>{space.address}</p>
                  </div>
                </div>
              ))} */}
            </OwlCarousel>
          </div>
          <div className='row d-flex '>
            {spaces.map((space, spaceIndex) => (
              <>
                <div className='col-md-6 col-sm-12  '>
                  {" "}
                  <div
                    id={`carousel-${spaceIndex}`}
                    className='carousel slide mb-4 '
                    data-bs-ride='carousel'
                    key={spaceIndex}
                  >
                    {/* Carousel Indicators */}
                    <div className='carousel-indicators'>
                      {space.imgSrc.map((_, index) => (
                        <button
                          type='button'
                          data-bs-target={`#carousel-${spaceIndex}`}
                          data-bs-slide-to={index}
                          className={index === 0 ? "active" : ""}
                          aria-current={index === 0 ? "true" : undefined}
                          aria-label={`Slide ${index + 1}`}
                          key={index}
                        ></button>
                      ))}
                    </div>

                    {/* Carousel Inner */}
                    <div className='carousel-inner'>
                      {space.imgSrc.map((img, index) => (
                        <div
                          className={`carousel-item ${
                            index === 0 ? "active" : ""
                          }`}
                          key={index}
                        >
                          <img
                            src={img}
                            className='d-block w-100'
                            alt={`${space.title} - Slide ${index + 1}`}
                          />
                        </div>
                      ))}
                    </div>

                    {/* Carousel Controls */}
                    <button
                      className='carousel-control-prev'
                      type='button'
                      data-bs-target={`#carousel-${spaceIndex}`}
                      data-bs-slide='prev'
                    >
                      <span
                        className='carousel-control-prev-icon'
                        aria-hidden='true'
                      ></span>
                      <span className='visually-hidden'>Previous</span>
                    </button>
                    <button
                      className='carousel-control-next'
                      type='button'
                      data-bs-target={`#carousel-${spaceIndex}`}
                      data-bs-slide='next'
                    >
                      <span
                        className='carousel-control-next-icon'
                        aria-hidden='true'
                      ></span>
                      <span className='visually-hidden'>Next</span>
                    </button>
                  </div>
                  <div className='row '>
                    <div className='col-md-12 col-sm-12'>
                      <h5 className='text-dark'>{space.title}</h5>
                      <p className='text-dark'>{space.address}</p>
                      <button
                        className='btn btn-primary w-100'
                        onClick={handleBookNow}
                      >
                        {" "}
                        Book Now
                      </button>
                    </div>
                  </div>
                </div>
              </>
            ))}
          </div>
          <div className='row mt-5 d-flex justify-content-between text-center'>
            <div className='col-md-6 col-sm-6'>
              <a
                href='tel:+919116109415'
                className='text-decoration-none btn btn-primary d-flex align-items-center justify-content-center'
              >
                <i className='fas fa-phone-alt me-2'></i> Call Now
              </a>
            </div>
            <div className='col-md-6 col-sm-6'>
              <a
                href='https://wa.me/9116109415'
                target='_blank'
                rel='noopener noreferrer'
                className='text-decoration-none btn btn-success d-flex align-items-center justify-content-center'
              >
                <i className='fab fa-whatsapp me-2'></i> WhatsApp
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
