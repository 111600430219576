import Header from "../component/Header";
import Footer from "../component/Footer";
import CoworkingForm from "../component/CoworkingForm";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export default function ClientPanel() {
  const navigate = useNavigate();
  const [isLogin, setIslogin] = useState(localStorage.getItem("islogin"));
  const [userType, setuserType] = useState(localStorage.getItem("user"));
  useEffect(() => {
    if (!isLogin) {
      navigate("/login");
    }
  }, [isLogin]);
  return (
    <>
      <Header />
      <h1 className='text-center'>Client Panel</h1>
      <br />
      <CoworkingForm />
      <Footer />
    </>
  );
}
