
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import NoPage from "./pages/NoPage";
import SpacesPage from "./pages/SpacesPage";
import AboutPage from "./pages/AboutPage";
import ServicesPage from "./pages/ServicesPage";
import ContactPage from "./pages/ContactPage";
import ErrorPage from "./pages/ErrorPage";
import LoginPage from "./pages/LoginPage";
import ClientPanel from "./pages/ClientPanel";
import AdminPage from "./pages/AdminPage";




function App() {
  return (

    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/services" element={<ServicesPage />} />
        <Route path="/spaces" element={<SpacesPage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/client" element={<ClientPanel />} />
        <Route path="/admin" element={<AdminPage />} />
        <Route path="/*" element={<ErrorPage />} />

      </Routes>
    </BrowserRouter>

  );
}

export default App;
