import React from "react";
// import Carousel from "./Carousel";

export default function Slider() {
  return (
    <>
      <div id='slider' data-aos='fade-up'>
        <div className='container-fluid padding-side'>
          <div className='d-flex rounded-5' style={divStyle}>
            <div className='row text-start px-5 pt-5'>
              <div className='col'>
                {" "}
                <h1
                  className='display-1 '
                  style={{
                    marginBottom: 380,
                    marginLeft: 100,
                    fontFamily: "REZ Regular",
                  }}
                >
                  Invisispace
                </h1>
              </div>
            </div>

            {/* <Carousel /> */}
          </div>
        </div>
      </div>
    </>
  );
}
const divStyle = {
  backgroundImage: "url(images/bg-invis.png)",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  height: "85vh",
  backgroundPosition: "center",
};
